<template>
    <div class="zuluGuardMain">
        <section class="blockElement space bg-white whoWeAre zuluguard-guide">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-11">
                        <div class="boxed bodyBG text-center">
                            <h2 v-html="$t('zuluGuard_guide.guidetitle')"></h2>
                            <p class="mb-2" v-html="$t('zuluGuard_guide.guidecontent1')"></p>
                            <p>{{$t('zuluGuard_guide.guidecontent2')}}</p>
                            <h3>{{$t('zuluGuard_guide.guidecontent3')}}</h3>
                            <p>{{$t('zuluGuard_guide.guidecontent4')}} </p>
                            <p v-html="$t('zuluGuard_guide.guidecontent5')"> </p>
                            <p v-html="$t('zuluGuard_guide.guidecontent6')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space">
            <div class="container">
                <div class="row mb-3 mb-md-4 align-items-center">
                    <div class="col-12 col-md-6">
                        <h3 class="bold">{{$t('zuluGuard_guide.guidecontent7')}} </h3>
                        <p>{{$t('zuluGuard_guide.guidecontent8')}} </p>
                    </div>
                    <div class="col-12 col-md-6">
                        <v-lazy-image width="622" height="192" src="/assets/images/ZG-funds-to-invest.png" :alt="$t('zuluGuard_guide.guidecontent32')" :title="$t('zuluGuard_guide.guidecontent33')" />
                    </div>
                </div>
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-12 col-md-6">
                        <p>{{$t('zuluGuard_guide.guidecontent9')}} </p>
                    </div>
                    <div class="col-12 col-md-6">
                        <v-lazy-image width="622" height="192" src="/assets/images/ZG-advanced.png" :alt="$t('zuluGuard_guide.guidecontent34')" />
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white whoWeAre zuluguard-guide">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <h3 class="bold">{{$t('zuluGuard_guide.guidecontent10')}}</h3>
                        <p>{{$t('zuluGuard_guide.guidecontent11')}} </p>
                        <p>{{$t('zuluGuard_guide.guidecontent12')}} </p>
                    </div>
                    <div class="col-12 col-md-6 text-center">
                        <v-lazy-image width="220" height="240" src="/assets/images/takeProfit.svg" :alt="$t('zuluGuard_guide.guidecontent34')" />
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h3 class="bold">{{$t('zuluGuard_guide.guidecontent13')}} </h3>
                        <p class="mb-1">{{$t('zuluGuard_guide.guidecontent31')}} </p>
                        <p class="mb-1" v-html="$t('zuluGuard_guide.guidecontent14')"></p>
                        <p v-html="$t('zuluGuard_guide.guidecontent15')"></p>
                    </div>
                    <div class="col-12 col-md-4">
                        <h3 class="bold">{{$t('zuluGuard_guide.guidecontent16')}} </h3>
                        <p>{{$t('zuluGuard_guide.guidecontent17')}} </p>
                        <p>{{$t('zuluGuard_guide.guidecontent18')}} </p>
                        <!-- <p class="f-14"> You can read more about capital protection <router-link class="secondary" to="/capital-protection-info">here</router-link> </p> -->
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row text-center">
                    <div class="col-12 col-md-4 mb-3 mb-md-0">
                        <div class="boxed bodyBG p-3 h-100">
                            <h4 v-html="$t('zuluGuard_guide.guidecontent19')"></h4>
                            <p class="mb-0" v-html="$t('zuluGuard_guide.guidecontent20')"></p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3 mb-md-0">
                        <div class="boxed bodyBG p-3 h-100">
                            <h4>{{$t('zuluGuard_guide.guidecontent21')}} </h4>
                            <p class="mb-0"> {{$t('zuluGuard_guide.guidecontent22')}} </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="boxed bodyBG p-3 h-100">
                            <h4>{{$t('zuluGuard_guide.guidecontent23')}} </h4>
                            <p class="mb-0">{{$t('zuluGuard_guide.guidecontent24')}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <h3 class="bold">{{$t('zuluGuard_guide.guidecontent25')}} </h3>
                        <p class="mb-2">{{$t('zuluGuard_guide.guidecontent26')}} </p>
                        <p class="mb-2" v-html="$t('zuluGuard_guide.guidecontent27')"></p>
                        <p class="mb-2">{{$t('zuluGuard_guide.guidecontent28')}} </p>
                        <p class="mb-2">{{$t('zuluGuard_guide.guidecontent29')}} </p>
                        <p class="mb-2">{{$t('zuluGuard_guide.guidecontent30')}} </p>
                    </div>
                    <div class="col-12 col-md-5">
                        <v-lazy-image width="622" height="159" src="assets/images/ZG-setting.png" :alt="$t('zuluGuard_guide.guidecontent35')" :title="$t('zuluGuard_guide.guidecontent36')" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
    }
</script>
